import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import abductionIllustration from '../assets/images/abduction-illustration.svg'

// eslint-disable-next-line react/prop-types
function NotFoundPage({ location }) {
  return (
    <Layout location={location}>
      <SEO title="404: Seite nicht gefunden" />
      <div
        className="w-100 d-flex pt-5 flex-column justify-content-center align-items-center"
        style={{ marginBottom: '7rem' }}
      >
        <img
          alt="Ghost getting abducted by aliens"
          className="mx-auto w-auto"
          src={abductionIllustration}
          style={{ height: '40vh' }}
        />
        <h1 className="mt-4">Fehler 404</h1>
        <h2>Leider existiert diese Seite nicht...</h2>
      </div>
    </Layout>
  )
}

export default NotFoundPage
